import { Application } from 'stimulus'
import { Controller } from 'stimulus'

export default class MegamenuController extends Controller {
  static targets = ['burger', 'mainNav', 'togglerMainNav', 'togglerMainWrapper', 'wrapper', 'section']

  positionMegaMenuMobile () {
    this.sectionTargets.forEach(section => {
      const toggler = this.element.querySelector(`button#${section.id}-toggler`)
      const parentToggle = toggler.closest('.rnk-MegaMenu_MainItem')
      parentToggle.appendChild(section)
    })
  }

  positionMegaMenuDesk () {
    this.sectionTargets.forEach(section => {
      this.wrapperTarget.append(section)
    })
  }

  positionMenu () {
    if (this.burgerTarget.checked) {
      this.positionMegaMenuMobile()
    } else {
      this.positionMegaMenuDesk()
    }
  }

  removeOpenToSectionsWrapper () {
    this.sectionTargets.forEach(section => {
      section.removeAttribute('open')
    })
  }

  removeActiveToMainItems () {
    this.togglerMainNavTargets.forEach(toggler => {
      toggler.classList.remove('active')
    })
  }

  toggleTogglerState(id) {
    const megamenuToggler = this.element.querySelector(id)

    if (megamenuToggler.classList.contains('active')) {
      megamenuToggler.classList.remove('active')
    } else {
      this.removeActiveToMainItems()
      megamenuToggler.classList.add('active')
    }
  }

  togglerMainNavClick (event) {
    const sectionId = `#megamenu-${event.currentTarget.dataset.title}`
    const section = this.element.querySelector(sectionId)

    if (section.hasAttribute('open')) {
      section.removeAttribute('open')
    } else {
      const columns = section.querySelectorAll('.rnk-MegaMenu_SectionColumn')

      this.removeOpenToSectionsWrapper()
      section.setAttribute('open', '')
    }

    this.toggleTogglerState(`${sectionId}-toggler`)
  }

  closeMenu () {
    this.burgerTarget.checked = false
    this.positionMegaMenuDesk()
    this.removeOpenToSectionsWrapper()
    this.removeActiveToMainItems()
  }

  closeMenuClickingOutside () {
    const isClickInsideMegaMenuMain = this.mainNavTarget.contains(event.target)
    const isClickInsideMegaMenusWrapper = this.wrapperTarget.contains(event.target)

    if (!isClickInsideMegaMenuMain && !isClickInsideMegaMenusWrapper) {
      this.removeOpenToSectionsWrapper()
      this.removeActiveToMainItems()
    }
  }
}

const application = Application.start()
application.register('megamenu', MegamenuController)
